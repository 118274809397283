const menuElement = document.getElementById("menu_button");

menuElement.addEventListener("click", () => {
  event.preventDefault();
  const menuElement = document.getElementById("menu_button");
  const ul = document.getElementById("nav__ul");
  const nav = document.getElementById("nav");
  const login = document.getElementsByClassName("nav__user");

  if (menuElement.classList.contains("active")) {

    console.log("deakt");
    menuElement.classList.remove("active");
    ul.style.display = "none";
    login[0].style.display = "none";
    nav.style.height = "70px";

  } else {

    console.log("ak");
    menuElement.classList.add("active");
    ul.style.display = "block";
    login[0].style.display = "block";
    nav.style.height = "100vh";

  }
});